import Vue from 'vue'
import { Modal,message } from 'ant-design-vue';

import App from './App.vue'

Vue.config.productionTip = false

Vue.use(Modal);

new Vue({
  render: h => h(App),
  mounted() {
    Vue.prototype.$message = message;
  }
}).$mount('#app')
