import axios from 'axios';
const consts = require('./consts');
import {storage} from '@/utils/storage'
import { message } from 'ant-design-vue';

const instance = axios.create({
    baseURL: consts.ServerUrl+'/api/web',
    timeout: 5000,
    header: {
        'content-type': 'application/json',
    },
});
// 请求拦截器
instance.interceptors.request.use(
    config => {
        config.headers['X-Access-Token'] = storage.getLoginToken();
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    response => {
        console.log("response=》",response)
        if(response.status === 200 && response.data.code === 200){
            return response.data;
        }else if(response.data && response.data.code === 401){
            storage.setLoginToken(false);
            storage.setUserInfo(false);
        }
        if (response.config.is_alert) {
            message.error(response.data.message || "网络请求失败");
        }
        return Promise.reject(response);
    },
    error => {
        return Promise.reject(error);
    }
);
instance.getGlobalConfig = function() {
    return this.get("/config/globalWeb", {});
};
instance.getUserInfo = function() {
    if (!storage.isLogin()) {
        return Promise.reject({
            code: 401,
            message: '请认证',
        })
    }
    return this.get("/user/account", {})
};
export {
    instance as api
}