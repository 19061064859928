const consts = require('./consts');
const storage = {
  setItem: function(key, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  },
  getItem: function(key) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  },
  removeItem: function(key) {
    return localStorage.removeItem(key);
  },
  getLoginToken() {
    return this.getItem(consts.LoginKey);
  },
  setLoginToken(token) {
    if (!token) {
      return this.removeItem(consts.LoginKey);
    }
    return this.setItem(consts.LoginKey, token);
  },
  isLogin() {
    return this.getLoginToken() ? true : false;
  },
  getSharePid() {
    return this.getItem(consts.SharePidKey);
  },
  setSharePid(shareId) {
    return this.setItem(consts.SharePidKey, shareId);
  },
  setUserInfo(user) {
    if (!user) {
      return this.removeItem(consts.UserInfoKey);
    }
    return this.setItem(consts.UserInfoKey, user);
  },
  getUserInfo() {
    return this.getItem(consts.UserInfoKey);
  },
  getGlobalConfig() {
    return this.getItem(consts.GlobalConfigKey);
  },
  setGlobalConfig(config) {
    if (!config) {
      return this.removeItem(consts.GlobalConfigKey);
    }
    return this.setItem(consts.GlobalConfigKey, config);
  },
};
export {
  storage
}