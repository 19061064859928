import {api} from './api'
import {storage} from '@/utils/storage'

const util = {
  getGlobalConfig() {
    let config = storage.getGlobalConfig();
    let result = api.getGlobalConfig().then(function (resp) {
      storage.setGlobalConfig(resp.data);
      return resp.data;
    });
    if (config) {
      return Promise.resolve(config);
    } else {
      return result
    }
  },
  getUserInfo() {
    return api.getUserInfo().then(function (resp) {
      storage.setUserInfo(resp.data);
      return resp.data;
    });
  },
  getNowTime() {
    return Math.round(new Date() / 1000);
  },
};
export {
  util
};