<template>
  <div id="app">
    <div id="appContainer" class="layout__StyledContainer-sc-p2jnls-0 bqJzIM">
      <div class="float-button">
        <label class="switch">
          <input type="checkbox" :checked="!isMuted" id="volume" @click="toggleMute">
          <span class="slider round"></span>
          <span class="switch-text">音乐</span>
        </label>
        <div class="service-button">
          <a href="javascript:void(0)" @click="showContactModal"><img src="assets/kefu.png"></a>
        </div>
      </div>
      <header id="header-tpl2" style="backdrop-filter:"
              class="layout-header-template2__StyledHeaderContainer-sc-5nyn3q-9 htOfEZ">
        <div class="layout-header-template2__StyledHeaderItem-sc-5nyn3q-6 gWWRvu header-item-logo">
          <img src="assets/logo-31ed68bef23e4838884cb140799570c9.jpg">
        </div>
        <div class="layout-header-template2__StyledHeaderContent-sc-5nyn3q-7 khLdpD">
          <div class="left">
            <!--            <div class="layout-header-template2__StyledHeaderItem-sc-5nyn3q-6 gWWRvu">首页</div>-->
            <!--            <div class="layout-header-template2__StyledHeaderItem-sc-5nyn3q-6 gWWRvu">游戏中心</div>-->
            <!--            <div class="layout-header-template2__StyledHeaderItem-sc-5nyn3q-6 gWWRvu">会员中心</div>-->
            <div v-if="false" class="layout-header-template2__StyledHeaderItem-sc-5nyn3q-6 gWWRvu">
              <div id="headerSearchDom" style="display:flex;align-items:center;width:100%">
                  <span
                      class="ant-input-search search__StyledSearchInputNew-sc-ntqhds-0 iZHQuJ ant-input-search-enter-button ant-input-group-wrapper">
                    <span class="ant-input-wrapper ant-input-group">
                      <span
                          class="ant-input-search search__StyledSearchInputNew-sc-ntqhds-0 iZHQuJ ant-input-search-enter-button ant-input-affix-wrapper">
                        <input type="text" value=""
                               placeholder="幻兽帕鲁,gta5,壁纸引擎"
                               class="ant-input">
                        <span class="ant-input-suffix">
                          <span role="button"
                                aria-label="close-circle"
                                tabindex="-1"
                                class="anticon anticon-close-circle ant-input-clear-icon ant-input-clear-icon-hidden">
                            <svg viewBox="64 64 896 896"
                                 focusable="false"
                                 data-icon="close-circle"
                                 width="1em" height="1em"
                                 fill="currentColor"
                                 aria-hidden="true">
                              <path
                                  d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                            </svg>
                          </span>
                        </span>
                      </span>
                      <span class="ant-input-group-addon">
                        <button type="button" class="ant-btn ant-input-search-button ant-btn-primary">
                          <div class="search__StyledSearchBtn-sc-ntqhds-3 gPiSoS">
                            <span role="img"
                                  aria-label="search"
                                  class="anticon anticon-search">
                              <svg viewBox="64 64 896 896"
                                   focusable="false"
                                   data-icon="search"
                                   width="1em"
                                   height="1em"
                                   fill="currentColor"
                                   aria-hidden="true">
                                <path
                                    d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                              </svg>
                            </span>
                          </div>
                        </button>
                      </span>
                    </span>
                  </span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="top-button">
              <a class="head-pic" href="javascript:void(0)" @click="showOrderModal"><img src="assets/buy.png"></a>
              <a class="head-pic" href="javascript:void(0)" @click="showQunModal"><img src="assets/jiao.png"></a>
              <a class="head-pic" href="javascript:void(0)" @click="showJoinModal"><img src="assets/add.png"></a>
              <div class="download-t">
                <a class="head-pic" href="javascript:void(0)">
                  <img src="assets/xz.png">
                </a>
                <div class="xianlu" :style="{right:isLogin?'115px':''}">
                  <i class="top-arrow"></i>
                  <div class="xianlu-bg">
                    <a @click="downloadApp1"><img src="assets/xz1.png"></a>
                    <a @click="downloadApp2"><img src="assets/xz2.png"></a>
                  </div>
                </div>
              </div>
            </div>

            <!--                    <div class="layout-header-template2__StyledHeaderItem-sc-5nyn3q-6 gWWRvu">-->
            <!--                        <button id="semDownloadBtn" data-agl-cvt="6"-->
            <!--                                sg-data-eventtype="DOWNLOAD_BUTTON_CLICK"-->
            <!--                                sg-data-convertid="2718593" type="button"-->
            <!--                                class="ant-btn dd-button__StyledDDButton-sc-tnjg8b-0 gNzaqF header-down-btn ant-btn-primary">-->
            <!--                            <svg width="17" height="18" xmlns="http://www.w3.org/2000/svg">-->
            <!--                                <path d="M7.468 8.308V1.48L.01 2.653v5.655h7.46Zm.932.934v6.975l8.39 1.322V9.242H8.4Zm0-.934h8.39V.011L8.4 1.331v6.977Zm-.932.934H.01v5.655l7.46 1.174V9.24Z"-->
            <!--                                      fill="#000" fill-rule="nonzero"></path>-->
            <!--                            </svg>-->
            <!--                            <span>下载客户端</span>-->
            <!--                        </button>-->
            <!--                    </div>-->
            <div v-if="!isLogin" class="layout-header-template2__StyledLogin-sc-5nyn3q-0 haBjIh"
                 @click="showWeixinModal">
              <div class="tip">未登录</div>
            </div>
            <div v-if="isLogin" class="layout-header-template2__StyledLogin-sc-5nyn3q-0 haBjIh"  @click="showConfirmQuit"
                 :style="{background:'url(assets/profile.png)','border-radius':'50%','background-size': '36px'}">
            </div>
            <span v-if="isLogin" class="user-nickname" @click="showConfirmQuit">{{ user.nickname }}</span>
          </div>
        </div>
      </header>
      <div id="content" class="layout__StyledLayoutContainer-sc-p2jnls-2 ewFmWZ">
        <div id="scollContent" class="active-gog13__StyledContainer-sc-zjgu5i-11 iTWFhg">
          <div class="active-gog13__StyledHeaderContent-sc-zjgu5i-10 eZkgpD animation1"
               style="background-image:url(https://cdk-assets.oss-cn-hangzhou.aliyuncs.com/video/index_bg.jpg)">
            <video ref="videoPlayer" src="https://cdk-assets.oss-cn-hangzhou.aliyuncs.com/video/index_bg.mp4" autoplay :muted="isMuted"
                   class="active-gog13__StyledVideoBox-sc-zjgu5i-5 jnmjNX"></video>
            <div class="shadow"></div>
            <div class="active-gog13__StyledInfo-sc-zjgu5i-9 waoAL">
              <div class="content">
                <!--                <div class="ant-row ant-row-middle" style="margin-bottom:12px;padding-left:8px">-->
                <!--                  <div class="title animation1">菜鸟游戏盒子</div>-->
                <!--                  <span class="platformIcon"></span>-->
                <!--                </div>-->
                <!--                <div class="engName animation1">TEKKEN 8</div>-->
                <!--                <div class="ant-row ant-row-middle title-price">-->
                <!--                  <div class="discount">-83%</div>-->
                <!--                  <div class="unit">￥</div>-->
                <!--                  <div class="price">86</div>-->
                <!--                  <div class="cost-price">原价:<span>￥-->
                <!--                    &lt;!&ndash; &ndash;&gt;489</span>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="ant-row ant-row-middle">
                  <div  @click="showActivityModal" data-agl-cvt="7"
                       class="semBuybtn-bac active-gog13__StyledBuyBtn-sc-zjgu5i-7 huKUFa">
                    <!--                    <span class="buy-text" style="margin-right:10px">￥5</span>-->
                    <span class="buy-text"> 购买VIP</span>
                  </div>
                  <div  @click="showFreeModal" data-agl-cvt="7"
                       class="free-play active-gog13__StyledVipBtnBox-sc-zjgu5i-8 eDXnDG">
                    <input class="invit-input" type="text" v-model="invitCode" placeholder="输入邀请码，免费获取点卡"/>
                    <span class="buy-text"> 免费玩</span>
                  </div>
                </div>
              </div>
              <div class="active-gog13__StyledStepBox-sc-zjgu5i-4 igLwfX">
                <div class="active-gog13__StyledTitle-sc-zjgu5i-3 bzuikr">
                  <div class="name">安装步骤</div>
                </div>
                <div class="step-box" id="semDownloadBtn"
                     data-agl-cvt="6" sg-data-eventtype="DOWNLOAD_BUTTON_CLICK"
                     sg-data-convertid="2718593">
                  <img class="dd-image__StyledImagesContainer-sc-17lqgno-1 ebmwwL"
                       src="assets/step1.png"
                       draggable="false">
                  <img class="dd-image__StyledImagesContainer-sc-17lqgno-1 ebmwwL"
                       src="assets/step2.png"
                       draggable="false">
                  <img class="dd-image__StyledImagesContainer-sc-17lqgno-1 ebmwwL"
                       src="assets/step3.png"
                       draggable="false">
                  <img class="dd-image__StyledImagesContainer-sc-17lqgno-1 ebmwwL"
                       src="assets/step4.png"
                       draggable="false">
                </div>
              </div>
            </div>
          </div>
          <!--                <div class="active-gog13__StyledDetailContent-sc-zjgu5i-6 iJLGEV">-->
          <!--                    <div class="left">-->
          <!--                        <div class="active-gog13__StyledTitle-sc-zjgu5i-3 bzuikr">-->
          <!--                            <div class="name">游戏介绍</div>-->
          <!--                        </div>-->
          <!--                        <div class="gamedescMain">-->
          <!--                            <div>-->
          <!--                                &lt;!&ndash;HTML&ndash;&gt;-->
          <!--                                <p>-->
          <!--                                    <strong>3D对战格斗游戏的最高峰「铁拳」系列最新作「铁拳8」</strong>-->
          <!--                                </p>-->
          <!--                                <h2 class="bb_tag">-->
          <!--                                    <span label="明显强调" style="font-style: italic; font-weight: bold; color: rgb(51, 153, 204);">▼总共32名角色激战</span>-->
          <!--                                </h2>-->
          <!--                                <p>焕然一新的角色外型，与经由最新硬体打破极限的精细高解析度模组，将「铁拳」战斗带往新境界。从角色肌肤滑落的水滴、摇曳的发丝、肌肉震动的高质感水准，加上更加鲜明的背景与关卡破坏表现，产生前所未有的临场感。</p>-->
          <!--                                <h2 class="bb_tag">-->
          <!--                                    <span label="明显强调" style="font-style: italic; font-weight: bold; color: rgb(51, 153, 204);">▼揭开新幕的「铁拳」传奇</span>-->
          <!--                                </h2>-->
          <!--                                <p>「电玩历史上延续最久」的壮大传奇，将以前作经过半年后为舞台。「风间仁」反抗命运，向父亲「三岛一八」发起的挑战，将发展为影响全世界的大斗争。描述风间仁面对残酷考验与强大敌人，他的成长与觉悟的故事，将在不朽的传奇刻下新章。</p>-->
          <!--                                <h2 class="bb_tag">-->
          <!--                                    <span label="明显强调" style="font-style: italic; font-weight: bold; color: rgb(51, 153, 204);">▼系列作史上最强爽快感的战斗</span>-->
          <!--                                </h2>-->
          <!--                                <p>保留「铁拳」特有的游玩手感与策略思考，新战斗系统「Heat」以及可在战斗中切换的2种操作风格，表现出积极进取的「互殴」。经由关卡内含的各种破坏机关，展开充满魄力的战斗场景。超必杀技「Rage-->
          <!--                                    Art」一旦爆发，玩家与观战者都将迈向狂热的顶点。</p>-->
          <!--                                <h2 class="bb_tag">-->
          <!--                                    <span label="明显强调" style="font-style: italic; font-weight: bold; color: rgb(51, 153, 204);">▼用新模式享受铁拳生活吧</span>-->
          <!--                                </h2>-->
          <!--                                <p>在新的单人游玩模式「街机任务」中，可以使用照自己喜好制作的人像巡回各地街机，与劲敌对战。随着故事发展学习战斗的基础知识与实用技巧，获得人像和可游玩角色的自订道具吧。</p>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                    <div class="right">-->
          <!--                        <div class="active-gog13__StyledTitle-sc-zjgu5i-3 bzuikr">-->
          <!--                            <div class="name">详细介绍</div>-->
          <!--                        </div>-->
          <!--                        <div style="margin-top:20px" class="active-gog13__StyledRow-sc-zjgu5i-2 eWqyPY">-->
          <!--                            <div class="label">开发商：</div>-->
          <!--                            <div class="value">Bandai Namco Studios-->
          <!--                                Inc.</div>-->
          <!--                        </div>-->
          <!--                        <div class="active-gog13__StyledRow-sc-zjgu5i-2 eWqyPY">-->
          <!--                            <div class="label">首发日期：</div>-->
          <!--                            <div class="value">2024-01-26</div>-->
          <!--                        </div>-->
          <!--                        <div class="active-gog13__StyledRow-sc-zjgu5i-2 eWqyPY">-->
          <!--                            <div class="label">支持语言：</div>-->
          <!--                            <div class="value">简体中文,繁体中文,英文,日文,西班牙文,意大利文,俄文,阿拉伯文,韩文,泰文,德文,波兰文,葡萄牙文,法文</div>-->
          <!--                        </div>-->
          <!--                        <div style="margin-bottom:10px" class="active-gog13__StyledRow-sc-zjgu5i-2 eWqyPY">-->
          <!--                            <div class="label">下载大小：</div>-->
          <!--                            <div class="value">需要 100 GB 可用空间</div>-->
          <!--                        </div>-->
          <!--                        <div class="active-gog13__StyledTitle-sc-zjgu5i-3 bzuikr"></div>-->
          <!--                        <div style="margin-top:10px" class="active-gog13__StyledRow-sc-zjgu5i-2 eWqyPY">-->
          <!--                            <div class="label">售后</div>-->
          <!--                            <div class="value">-->
          <!--                                <div class="row">-->
          <!--                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAABAxJREFUOE91VFtoXFUUXetMZvIY0ySmQf2wLaaUVE1VmgqKotQHNkKLhIAiKrU1P6I1VpKMk3vn3Bs6yYTURKHiR6GCglVCUfxQ2+KD/ijio5J8iKi1FovvpEmTzHjv2bJDJsRQz+c5+6y919prb2LVsdaaZDJ5bRzHdwPYAeA6AGsA/APgZwCfOOfenZub+2x4eHhm9X+uvBgaGqorlUp7ADwBoAWAA/CHiPwFoILkVQDSAGZEZNw5NxYEwTcrMZYBrbVXkhwh2QmgBOB9/SQiP0ZRNJNKpYxzrtEYcxsATboewMcLCwsP5/P5X8ugi4C5XG5NIpE4BOAhAD+ISHZubu54oVCYvoQkFQA2JBKJLudcqaqqqtDb27tMnZ2dnYnW1tanAAwBOGuM2dvf33/KWqvJNihta+0ZABwYGNgYRdF8EATnVOvJyUm2tbXVFIvFLalUaqKvr29agzY7546RXCcij+dyuTe1KmvtWmPMawDqAHSTrBORYZJfRlH0TBAEFzQuCIIOkmMkfc/zjjAMw6cBjInI25WVlbs1iwZ2d3dX19fXF0juFZHzJKsA1JIcjOP4oLVWddbENxtjTorIh8lkco8CvgfgXgCP+b7/elkza+0mkq0kPQA3ABAROQUgLyKfW2u188hkMo3V1dVvaUwcx/cp4FkAl8Vx3B4EwadluiTHSW5VuwDQ6vQUAcw7515SttZa1TdF0pLsFZF2BZwSkT9FZJe1dmIJsMYYkwHQBqARwBYAFwFowojkEc/z3tGqNX5JthcBPKKA6iHVY6fv+19pgIhos64neQWArSLSIyK/AHhWROJSqTRZ9p7GhmH4HEltWAeDIPiC5GaSOz3PO7lClxMAblrtQwCxiIyIyPNLlGuWuvwogHsUUKdjv7Y9juNBa22kHW5oaHiS5I0iouN2C4BZETmulEXkqLX2AyWTzWavrqys1EJmdMpord1ujDkmIr8bY9o9z/tOq+rq6ko2NzdXzc/P32GMOSwi3zvndpDURqiei/oFQbCPZAHAaEtLSz8LhUJtsVh8AcBukoenp6f3j4yM6IfFE4ahVjcO4Fvf97evlCAMw20AdBB0iXT4vn96cZbDMNQV9QaAZhE5lEqlBjOZzN/61tPTU5tOpx8EcCaXy6muepTZNmPMywA2iUhvLpd7Rate3jZBENxFUhfEehH5SGk45yYBXNCp0NmdnZ2tTqfTunEeILkPQJNOmXPugLV2YTHTCgrlrAcA3K4mFpGvSar3zqu5SW4UkTsBrNNFIiIHp6amXh0dHZ0v4/xnweplPp9viqKoA8D9AG4FcPmKpCWSE865ExUVFUez2ezpcnP+F7D8sAR8jXOuyRizVkQukvyN5Lk4jn9Se13Co/gXQoPvb1bDFp4AAAAASUVORK5CYII=">3小时极速退款-->
          <!--                                    (仅共享)</div>-->
          <!--                                <div class="row">-->
          <!--                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAVCAYAAACg/AXsAAAAAXNSR0IArs4c6QAAAxJJREFUOE+NlF1onFUQht/3fJvEoiGNVaOrdyJEiMSUgniloKJXNS0k/lWjFF1BU9g2Jbhkv2/O7iYKCgYTkA2KN/EH/LlRRMReaFu0FylqC7USS1Oyhb0QwUVRm29GjuyWNG5Kz+XhzHNm5p15iU1OoVDo6+rqKgJ4kGSyurr64cLCwoV2z7nxcmJi4uru7u77ST4P4AEAEYBfAVQBfNTf3//j6Ohouj6OpVLpLgB3ALgGwK1mtp3k7c3gL0geN7MnAdxmZudJLpvZDyTPmFnNzA4HyDKAbDMoA+A3kidUdbbRaHy1srLy1+Dg4I2quhfAbgC3AOgF8A+AC2Y2HSB/AnAAPgWwFH5R1aPe+983llooFG7q6OgYymQyQ6q6m+R2M5trQc6q6k4RCVld0fHe7yX5FoA3WpCf0jQd9t6fuyICAO/9IyQ/uAgxs9NRFA1PTU2tbAaZmZm5fm1tbSxN0++890e894+RfG9TyPj4eNfc3NzfLaCIXOucex3A42ZWTZLkxctCSqXS3ar6KIB3ROR7EdnqnHsVwNMATgB4No7jpctCyuXyATN7pRmQN7ORMHhmdorkc3EcfxsybAtR1YdDY0XkBpLzJMNM1AFsA3AGQC6O48OtEi+BeO//ALAcRdHOVmOTJMlGUTQPYJeZ/eycyxWLxa8B2DrIEyQXAcwGic8C2KKq94nIydajSqVys6qOqeoREflmvWoi4kgeJBnKngyQtwE8A2C6r6+vlMvl2m7qBkhQ63MAQyR3sVKp7FDVT8ysG8D+Wq22uNnKB1A+n9/S09Mjzrk8gEOZTOYpNlMLWzpLUkm+1tnZOT85OdnYOHgicp1z7iCAFwA0zGxPkiSH/vOTarXaUa/Xx8zsZZLBEj4DIKp6WkTWwu+9vb13AigBuMfMwqbn4zh+PzT7oimNjIxEAwMDD5GcbvpLDcC8mS2RDMM3HOQOW+6ceylN0y9FREMS/3O2crkczGcfgD0AtjZlDe/qZrbonHuzWCz+comztVs4EbkKwL0k95MMZXycpum72Wz2WDv1/gWc0IryI9dMcwAAAABJRU5ErkJggg==">被盗包赔-->
          <!--                                    错误包换</div>-->
          <!--                                <div class="row">-->
          <!--                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAAAXNSR0IArs4c6QAAAvxJREFUOE91lE9oXGUUxc/93sgwkzSh1jTGKnTVhSBaoytL1YJIN4piaSm2QbNotSIESQ0Zzdz3Mgk2giurzdI/iCUiVqQUTKGNoGDb2HYpLrqQjDUIpmZCovPdIzfMlIfEt3p83O93z7vn3CfIPRMTE70xxmcB7APwIMlNIiL5GpIUkb8AXAMwkyTJmUqlcrNdc7s4y7JHAZwA8DjJ30XkCoBFB+SBrQY9JPtFZCuAiwDeHBsbu+R168BqtfpICOEjAHeTfD9Jkk9jjItLS0treVj7vbu7u5gkSU+M8UUReQ3Ab2Y2kKbpZVHVu0IIpwHsJPkKyRlVtY1A/z1T1SAi+0TkQwA/mdl+SdN00A9ITpEcc1i1Wr03SZLdMcZv0jS9lQdNT0/fUa/Xnwbwq6pebUEzETnugiTLsnMAHjKz3ar6s19O03RARD4ws8f8Uh5Yq9XuM7PzJOdIvqqqf6vqjhDCHICrDnSH5ldWVg52dnaWY4x7AOwVkQOuOoTwY7PZnG0rVdWyiAwA+IXkef+ikZGRzeVy+TMADztwWUS+7OjoOLK8vHyo5XQJQJHkKoBbJJ9T1e//b67eJEmSUySf9xneFJF1hcVisRRCeLKl8CDJdwBcajQas1NTU549tBS+1FL4bV4hyXWFG83wkBtlZrs2mmGMcVZE5szsWHuGIvKdC3PgywBOAXjXzN72jrVabVuMcVej0TjryoaGhkpdXV2bReTPhYWFf/r6+p5yl9M0ve4uhxDGAQwDONrO4ecA+t01kqfzORwfH99jZq+HELaZ2Q9m9lbOIM/hfk8EgCtmdmB9U7Is6wfgm3IPgJNm9snq6upiuVzeTvIrEXGTbpA8R/K9YrEoa2trPSEEN/EYgAUzO6yq8/lddoUnROQJkn+0dvlO/0kAGOzt7Z2p1+tbQghvAHiA5E4R2ULyQqFQOF6pVOZv73I7Dqq6NYTwDIAXPOweHQAfl0ql0eHh4cbk5OT9zWbzawCbfNUAfFEoFM6Mjo4uthn/At1YmOW9sqamAAAAAElFTkSuQmCC">官方正版</div>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                        <div class="active-gog13__StyledTitle-sc-zjgu5i-3 bzuikr">-->
          <!--                            <div class="name">最低配置要求</div>-->
          <!--                        </div>-->
          <!--                        <div class="active-gog13__StyledSystem-sc-zjgu5i-1 fpaMZO">-->
          <!--                            <div class="__htmlBox">-->
          <!--                                <div>-->
          <!--                                    <ul class="bb_ul list-paddingleft-2"-->
          <!--                                        style="list-style-type: square;">-->
          <!--                                        <li>-->
          <!--                                            <strong>操作系统:</strong>Windows-->
          <!--                                            10 64-Bit-->
          <!--                                            <br>-->
          <!--                                        </li>-->
          <!--                                        <li>-->
          <!--                                            <strong>处理器:</strong>Intel-->
          <!--                                            Core i5-6600K/AMD Ryzen 5-->
          <!--                                            1600-->
          <!--                                            <br>-->
          <!--                                        </li>-->
          <!--                                        <li>-->
          <!--                                            <strong>内存:</strong>8 GB-->
          <!--                                            RAM-->
          <!--                                            <br>-->
          <!--                                        </li>-->
          <!--                                        <li>-->
          <!--                                            <strong>图形:</strong>Nvidia-->
          <!--                                            GeForce GTX 1050Ti/AMD-->
          <!--                                            Radeon R9 380X-->
          <!--                                            <br>-->
          <!--                                        </li>-->
          <!--                                        <li>-->
          <!--                                            <strong>DirectX 版本:</strong>12-->
          <!--                                            <br>-->
          <!--                                        </li>-->
          <!--                                        <li>-->
          <!--                                            <strong>网络:</strong>宽带互联网连接-->
          <!--                                            <br>-->
          <!--                                        </li>-->
          <!--                                        <li>-->
          <!--                                            <strong>存储空间:</strong>需要-->
          <!--                                            100 GB 可用空间-->
          <!--                                            <br>-->
          <!--                                        </li>-->
          <!--                                    </ul>-->
          <!--                                </div>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                </div>-->
        </div>
      </div>
      <!--        <div class="layout-footer-template2__StyledFooterContainer-sc-17zv252-5 hzFkdQ">-->
      <!--            <div class="layout-footer-template2__StyledFooterContentBox-sc-17zv252-4 gysGPz">-->
      <!--                <div width="390" class="layout-footer-template2__StyledFooterContentRow-sc-17zv252-3 bXxkYj">-->
      <!--                    <div class="layout-footer-template2__StyledFooterColTitle-sc-17zv252-2 dRrTxY">关于我们</div>-->
      <!--                    <div class="layout-footer-template2__StyledFooterColBox-sc-17zv252-1 kUmxDG">-->
      <!--                <span class="footer-item">产品名称：-->
      <!--                    &lt;!&ndash; &ndash;&gt;PC游戏商城</span>-->
      <!--                        <span class="footer-item">开发者：-->
      <!--                            &lt;!&ndash; &ndash;&gt;长沙时鱼科技有限公司</span>-->
      <!--                        <span class="footer-item">版本：-->
      <!--                            &lt;!&ndash; &ndash;&gt;1.0</span>-->
      <!--                        <span class="footer-item hover">公司简介</span>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <div class="layout-footer-template2__StyledFooterContentRow-sc-17zv252-3 ghtmbk">-->
      <!--                    <div class="layout-footer-template2__StyledFooterColTitle-sc-17zv252-2 dRrTxY">新手帮助</div>-->
      <!--                    <div class="layout-footer-template2__StyledFooterColBox-sc-17zv252-1 kUmxDG">-->
      <!--                        <span class="footer-item hover">用户权限</span>-->
      <!--                        <span class="footer-item hover">隐私权限</span>-->
      <!--                        <span class="footer-item hover">-->
      <!--                  <span>功能介绍</span>-->
      <!--                </span>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <div class="layout-footer-template2__StyledFooterContentRow-sc-17zv252-3 ghtmbk">-->
      <!--                    <div class="layout-footer-template2__StyledFooterColTitle-sc-17zv252-2 dRrTxY">下载客户端</div>-->
      <!--                    <div class="layout-footer-template2__StyledFooterColBox-sc-17zv252-1 kUmxDG">-->
      <!--                        <div class="layout-footer-template2__StyledPlatformBox-sc-17zv252-0 dxEKWS">-->
      <!--                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAg9JREFUSEvtls9qE1EUxr9zkxSykKBioRrHfzXallqqSKRWMW58g0xm8hi+QN4lydzM3WXj0qC2iC50q1AQArrSRSl0AhPvkRuSEP9kbtrGXWd7v3N+53x3zpkhzPlpNBp5IcRlAHeIaIOIFuk4jFqtJgqFwgVmNglXAWwSkcPM6wCWAYjJvIkQpdRCHMerJhERLQshgnK5vCelLAJ4DWDBViQzHwwg9Xp9KZPJ5LXWjhDiCjObylaY2bS9OEokhHjqum5HSvkEQMcGMOdjSBAEn4joli1Ia12sVqvvwzB8qLXesel/g0gpvwFYsgWdFPIZQOEUkuTA+OKllF8BXLTZxcxbvu+/DcPwsdb6lU0/Oh+8wlLKPQA3Zgh64HneuyAItonozQx6IzkcQJRSuSiK0tlslqcFRlFEjuPsl0qlfqfTSXe73VyS3uQxMel0Wh9rrczYwVg2smsXgBnGeEoCozP76JnneR9brdZ9Zn4B4KcZ6gRoCsD+CPIDwDlbhUKIbdd1d8MwLGmtX9r0f1786TBaHZscxi8ArtoiTrogvwM4/18hzWbzXiqVuj3spsjM1wHkAeQmwcz8yPf9naOslbFd/+qg3W6f6fV6m1prA7wJYIuInlcqlQ9HWSuJkCTrlFLZOI7XhRAbzLw2/GSvDX8i/toic10rSimn3+9fIqK7AIz91wCcnStkWve/AGelYYmIPU/RAAAAAElFTkSuQmCC">-->
      <!--                            <div>Windows</div>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <div class="layout-footer-template2__StyledFooterContentRow-sc-17zv252-3 ghtmbk">-->
      <!--                    <div class="logo">-->
      <!--                        <img style="width:auto" src="https://files.zuhaohao.com/demon/网站透明logo-31ed68bef23e4838884cb140799570c9.jpg">-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->
      <!--        </div>-->
      <div class="layout-footer-template2__StyledCompanyInfoContainer-sc-17zv252-12 kGTbIj">
        <!--        <div class="layout-footer-template2__StyledCompanyAddressRow-sc-17zv252-10 icytSd">XXX有限公司</div>-->
        <div class="layout-footer-template2__StyledCompanyInfoRow-sc-17zv252-11 gjLUCT">
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
             rel="noopener noreferrer" target="_blank">渝ICP备2024024978号-1-1</a>
        </div>
      </div>
      <!--      <div class="layout-footer-template2__StyledStatementTextRow-sc-17zv252-6 cIcvUU">-->
      <!--        <div>声明：本站不涉及游戏发行、推广及代理！</div>-->
      <!--        <div>温馨提示：响应国家防沉迷政策，未成年人（18岁以下）无法在本平台正常下单， 注意自我保护，谨防受骗上当。</div>-->
      <!--      </div>-->
    </div>


<!--    <link rel="prefetch" href="assets/q1.png">-->
<!--    <link rel="prefetch" href="assets/q2.png">-->
<!--    <link rel="prefetch" href="assets/q3.png">-->

    <a-modal
        class="weixin-modal"
        title="欢迎登录"
        width="400px"
        :visible="weixin_visible"
        @cancel="weixin_visible=false"
        :footer="null"
    >
      <div id="login_container" class="wei-pic">
          <img :src="weixin_qrlogin"/>
      </div>
      <button class="login-btn">微信扫码登录</button>
      <div class="login-text">
        登录/注册即代表同意<span class="primary" @click="showProtocolModal">《用户协议》</span>
      </div>
    </a-modal>
    <a-modal
        class="order-modal"
        title="获取记录"
        width="620px"
        :visible="order_visible"
        @cancel="order_visible=false"
        :footer="null"
    >
      <div class="get-order">
        <ul>
          <li>获取时间</li>
          <li>购买类型</li>
          <li>金额</li>
          <li>订单号</li>
          <li>获取方式</li>
          <li>兑换码</li>
        </ul>
        <ul v-for="(item, key) in orderList" :key="key">
          <li>{{ item.pay_at }}</li>
          <li>{{ item.code_type }}</li>
          <li>{{ item.amount }}</li>
          <li>{{ item.order_id }}</li>
          <li>{{ item.paytype }}</li>
          <li class="clipboard-btn" :data-clipboard-text="item.code">{{ item.code }}</li>
        </ul>
      </div>
    </a-modal>
    <a-modal
        class="exchange-modal"
        title="支付成功"
        width="300px"
        :visible="exchange_visible"
        @cancel="exchange_visible=false"
        :footer="null"
    >
      <div class="redemption-code-box">
        <p class="redemption-code">兑换码：{{exchangeCode}}</p>
        <!--以下为小字提醒，你看着设计-->
        <p class="code-tips">提示：请复制CDK激活码到盒子激活</p>
      </div>
      <button class="copy-btn clipboard-btn" :data-clipboard-text="exchangeCode">复制</button>
    </a-modal>
    <a-modal
        class="join-modal"
        title="扫描加入代理"
        width="286px"
        :visible="join_visible"
        @cancel="join_visible=false"
        :footer="null"
    >
      <div class="ewm-pic">
        <img src="assets/ewm.jpg"/>
      </div>
      <button v-if="isLogin && user.is_inviter" @click="redirctInviter" class="login-btn">代理后台</button>
    </a-modal>
    <a-modal
        class="contact-modal"
        title=""
        width="286px"
        :visible="contact_visible"
        @cancel="contact_visible=false"
        :footer="null"
    >
      <div class="ewm-pic">
        <img src="assets/wx-contact.jpg"/>
      </div>
      <button class="login-btn">微信扫码联系人工客服</button>
    </a-modal>
    <a-modal
        class="contact-modal"
        title=""
        width="286px"
        :visible="qun_visible"
        @cancel="qun_visible=false"
        :footer="null"
    >
      <div class="ewm-pic">
        <img src="assets/wx-contact.jpg"/>
      </div>
      <button class="login-btn">扫码加群</button>
    </a-modal>
    <a-modal
        class="activity-modal"
        title=""
        width="1024px"
        :visible="activity_visible"
        @cancel="closeActivityModal()"
        :footer="null"
        :closable="false"
    >
      <div class="taocan">
        <div class="taocan-left pubcolor">

          <div class="navBox">
            <div class="titleBox" @click="selectActivityIdx(0)">
              <span :class="{fontColorBox:activityIdx===0}">VIP</span>
            </div>
            <div class="titleBox" @click="selectActivityIdx(1)">
              <span :class="{fontColorBox:activityIdx===1}">SVIP</span>
            </div>
            <div class="titleBox" @click="selectActivityIdx(2)">
              <span :class="{fontColorBox:activityIdx===2}">至尊VIP</span>
            </div>
          </div>
          <ul v-if="activityIdx===0 && vip_list" class="taocan-ul2">
            <li v-for="(item, key) in vip_list" :key="key" :class="{active:item.code_type===vip_item.code_type}"
                @click="selectActivityVip(item)">
              <p class="ptext1">{{ item.name }}</p>
              <p class="ptext2 redtext">￥<span class="ttt34">{{ item.price }}</span></p>
              <p class="ptext3 redtext">{{ item.title }}</p>
            </li>
          </ul>
          <ul v-if="activityIdx===1 && svip_list" class="taocan-ul2">
            <li v-for="(item, key) in svip_list" :key="key" :class="{active:item.code_type===vip_item.code_type}"
                @click="selectActivityVip(item)">
              <p class="ptext1">{{ item.name }}</p>
              <p class="ptext2 redtext">￥<span class="ttt34">{{ item.price }}</span></p>
              <p class="ptext3 redtext">{{ item.title }}</p>
            </li>
          </ul>
          <ul v-if="activityIdx===2 && zvip_list" class="taocan-ul2">
            <li v-for="(item, key) in zvip_list" :key="key" :class="{active:item.code_type===vip_item.code_type}"
                @click="selectActivityVip(item)">
              <p class="ptext1">{{ item.name }}</p>
              <p class="ptext2 redtext">￥<span class="ttt34">{{ item.price }}</span></p>
              <p class="ptext3 redtext">{{ item.title }}</p>
            </li>
          </ul>
        </div>
        <div class="taocan-right pubcolor">
          <p class="uutext1">实付价：<span><b>￥</b>{{ vip_item.price }}</span></p>
          <p class="uutext2"><img :src="payQrcode"></p>
          <p class="uutext3">微信扫码付款</p>
          <p class="uutext4" @click="createActivityOrder">重新获取</p>
<!--          <button class="finish-pay" @click="queryActivityOrder">我已完成支付</button>-->
        </div>
      </div>
      <div class="equity-comparison">
        <div class="equity-comparison-title">VIP权益对比</div>
        <div class="cdk-form-box wrap">
          <div class="wrap_left_top"></div>
          <div class="wrap_right_top"></div>
          <div class="wrap_left_bottom"></div>
          <div class="wrap_right_bottom"></div>

          <div class="table">
            <div class="tr or-color weight-t">
              <div class="th">产品服务</div>
              <div class="th">VIP</div>
              <div class="th">SVIP</div>
              <div class="th">至尊VIP</div>
            </div>
            <div class="tr">
              <div class="th">远程协助服务</div>
              <div class="th">×</div>
              <div class="th">×</div>
              <div class="th">支持</div>
            </div>
            <div class="tr">
              <div class="th">正版入库游戏</div>
              <div class="th">×</div>
              <div class="th">×</div>
              <div class="th">支持</div>
            </div>
            <div class="tr">
              <div class="th">正版联机游戏</div>
              <div class="th">×</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
            </div>
            <div class="tr">
              <div class="th">普通PC游戏</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
            </div>
            <div class="tr">
              <div class="th">stitch游戏</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
            </div>
            <div class="tr">
              <div class="th">怀旧游戏</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
            </div>
            <div class="tr">
              <div class="th">手机游戏</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
            </div>
            <div class="tr">
              <div class="th">联机对战游戏</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
            </div>
            <div class="tr">
              <div class="th">游戏修改器</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
            </div>
            <div class="tr">
              <div class="th">分享免费加速器</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
              <div class="th">支持</div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal
        class="protocol-modal"
        title="用户协议"
        width="600px"
        :visible="protocol_visible"
        @cancel="protocol_visible=false"
        :footer="null"
        :zIndex="2000"
    >
      <div><p>
        本协议是您与游戏平台（简称“本站”）所有者之间就本站服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您同意本协议，本协议即构成对双方有约束力的法律文件。</p>
        <p>第1条 本站服务条款的确认和接纳</p>
        <p>1.1
          本站的各项电子服务的所有权和运作权归本站所有。用户同意所有注册协议条款并完成注册程序，才能成为本站的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。</p>
        <p>1.2
          用户点击同意本协议的，即视为用户确认自己具有享受本站服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。</p>
        <p>1.3 如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用本站。</p>
        <p>1.4
          本站保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。</p>
        <p>第2条 本站服务</p>
        <p>2.1
          本站通过互联网依法为用户提供互联网信息等服务，用户在完全同意本协议及本站规定的情况下，方有权使用本站的相关服务。</p>
        <p>2.2 用户必须自行准备如下设备和承担如下开支：</p>
        <p>（1）上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；</p>
        <p>（2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。</p>
        <p>第3条 用户信息</p>
        <p>3.1
          用户应自行诚信向本站提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且本站保留终止用户使用本站各项服务的权利。</p>
        <p>3.2
          用户在本站进行浏览、下单购物等活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，本站将予以严格保密，除非得到用户的授权或法律另有规定，本站不会向外界披露用户隐私信息。</p>
        <p>3.3
          用户注册成功后，将产生用户名和密码等账户信息，您可以根据本站规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知本站并向公安机关报案。</p>
        <p>3.4
          用户同意，本站拥有通过邮件、短信电话等形式，向在本站注册、购物用户、收货人发送订单信息、促销活动等告知信息的权利。</p>
        <p>3.5
          用户不得将在本站注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。</p>
        <p>3.6 用户同意，本站有权使用用户的注册信息、用户名、密码等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。
          第4条 用户依法言行义务</p>
        <p>4.1本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：</p>
        <p>
          （1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</p>
        <p>（2）从中国大陆向境外传输资料信息时必须符合中国有关法规；</p>
        <p>（3）不得利用本站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</p>
        <p>（4）不得干扰本站的正常运转，不得侵入本站及国家计算机信息系统；</p>
        <p>
          （5）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；</p>
        <p>（6）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</p>
        <p>（7）不得教唆他人从事本条所禁止的行为；</p>
        <p>（8）不得利用在本站注册的账户进行牟利性经营活动；</p>
        <p>（9）不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；</p>
        <p>4.2 用户应关注并遵守本站公布或修改的各类合法规则规定。</p>
        <p>4.3 本站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。</p>
        <p>4.4
          若用户未遵守以上规定的，本站有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。</p>
        <p>第5条 商品信息</p>
        <p>
          本站上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，本站不作特别通知。由于本站上商品信息的数量极其庞大，虽然本站会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本站网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；本站欢迎纠错，并会视情况给予纠错者一定的奖励。
          为表述便利，商品和服务简称为“商品”或“货物”。</p>
        <p>第6条 订单</p>
        <p>6.1
          在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与用户本人不一致的，收货人的行为和意思表示视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任。</p>
        <p>6.2
          除法律另有强制性规定外，双方约定如下：本站上销售方展示的商品和价格等信息仅仅是交易信息的发布，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的交易诉求；销售方收到您的订单信息后，只有在销售方将您在订单中订购的商品从仓库实际直接向您发出时（以商品出库为标志），方视为您与销售方之间就实际直接向您发出的商品建立了交易关系；如果您在一份订单里订购了多种商品并且销售方只给您发出了部分商品时，您与销售方之间仅就实际直接向您发出的商品建立了交易关系；只有在销售方实际直接向您发出了订单中订购的其他商品时，您和销售方之间就订单中该其他已实际直接向您发出的商品才成立交易关系。您可以随时登录您在本站注册的账户，查询您的订单状态。</p>
        <p>6.3
          由于市场变化及各种以合理商业努力难以控制的因素的影响，本站无法保证您提交的订单信息中希望购买的商品都会有货；如您拟购买的商品，发生缺货，您有权取消订单。</p>
        <p>6.4 当本站给您发货前，您和本站达成退款协议并且成功退款，视作要约的撤销，也就是您和我本站没有形成交易合同。</p>
        <p>第7条 配送</p>
        <p>7.1
          销售方将会把商品（货物）送到您所指定的收货地址，所有在本站上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。</p>
        <p>7.2 因如下情况造成订单延迟或无法配送等，销售方不承担延迟配送的责任：</p>
        <p>（1）用户提供的信息错误、地址不详细等原因导致的；</p>
        <p>（2）货物送达后无人签收，导致无法配送或延迟配送的；</p>
        <p>（3）情势变更因素导致的；</p>
        <p>（4）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。 第8条 所有权及知识产权条款</p>
        <p>8.1
          用户一旦接受本协议，即表明该用户主动将其在任何时间段在本站发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给本站所有，用户同意本站有权就任何主体侵权而单独提起诉讼。</p>
        <p>8.2
          本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在本站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。</p>
        <p>8.3
          用户同意并已充分了解本协议的条款，承诺不将已发表于本站的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。</p>
        <p>8.4
          本站是本站的制作者，拥有此本站内容及资源的著作权等合法权利，受国家法律保护，有权不时地对本协议及本站的内容进行修改，并在本站张贴，无须另行通知用户。在法律允许的最大限度范围内，本站对本协议及本站内容拥有解释权。</p>
        <p>8.5
          除法律另有强制性规定外，未经本站明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本站的信息内容，否则，本站有权追究其法律责任。</p>
        <p>8.6
          本站所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是本站或其内容提供者的财产，受中国和国际版权法的保护。本站上所有内容的汇编是本站的排他财产，受中国和国际版权法的保护。本站上所有软件都是本站或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。</p>
        <p>第9条 责任限制及不承诺担保</p>
        <p>9.1
          除非另有明确的书面说明，本站及其所包含的或以其它方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。</p>
        <p>9.2
          除非另有明确的书面说明，本站不对本站的运营及其包含在本站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。</p>
        <p>9.3
          本站不担保本站所包含的或以其它方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本站发出的电子信件、信息没有病毒或其他有害成分。</p>
        <p>9.4
          如因不可抗力或其它本站无法控制的原因使本站销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，本站会合理地尽力协助处理善后事宜。</p>
        <p>第10条 协议更新及用户关注义务</p>
        <p>
          根据国家法律法规变化及网站运营需要，本站有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上即生效，并代替原来的协议。用户可随时登录查阅最新协议；用户有义务不时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以且应立即停止接受本站依据本协议提供的服务；如用户继续使用本站提供的服务的，即视为同意更新后的协议。本站建议您在使用本站之前阅读本协议及本站的公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</p>
        <p>第11条 法律管辖和适用</p>
        <p>
          本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，在争议里需要邮寄的商品任何一方均可向有管辖权的中华人民共和国境内争议双方的所在地法院提起诉讼，网络虚拟类物品，计算机软件（游戏激活号，数字产品等）请于本所在地人民法院提起诉讼。</p>
        <p>第12条 其他</p>
        <p>12.1 本站网站所有者是指在政府部门依法许可或备案的本站网站经营主体。</p>
        <p>12.2
          《游戏试玩协议》、《游戏代购代充值协议》、《虚拟账号托管协议》、《VIP会员服务协议》属于本用户协议不可分割的一部分，与本用户协议具有同等法律效力。</p>
        <p>12.3
          本站尊重用户和消费者的合法权利，本协议及本上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本站欢迎用户和社会各界提出意见和建议，本站将虚心接受并适时修改本协议及本站上的各类规则。</p>
        <p>12.4 本协议内容中以黑体、加粗、下划线、斜体等方式显著标识的条款，请用户着重阅读。</p>
        <p>12.5 您在本站进行注册、登录即视为您完全接受本协议，请您再次确认已知悉并完全理解本协议的全部内容。</p></div>
    </a-modal>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue';
import {api} from '@/utils/api'
import {storage} from '@/utils/storage'
import {util} from '@/utils/util'

export default {
  name: 'App',
  components: {},
  data() {
    return {
      //控制不同的变量为true就显示不同弹窗
      weixin_visible: false,
      order_visible: false,
      exchange_visible: false,
      join_visible: false,
      contact_visible: false,
      qun_visible: false,
      activity_visible: false,
      protocol_visible: false,
      isMuted: true,

      orderList: null,
      activityIdx: 0,
      isLogin: false,
      user: null,
      config: {},
      vip_list: null,
      svip_list: null,
      zvip_list: null,
      vip_item: {code_type:'',price:'-'},
      weixin_qrlogin:'assets/weixin3x.png',

      xtoken:"",
      authId:null,

      invitCode:"",
      exchangeCode:"",
      payQrcode:"assets/empty.png",
      payOrderId:0,
    };
  },
  created() {
    let that = this;
    let isLogin = storage.isLogin();
    if(isLogin){
        util.getUserInfo().then(function (user) {
          that.user = user;
          that.isLogin = true;
        });
    }

    util.getGlobalConfig().then(function (resp) {
      that.config = resp;
    })
    this.getActivityConfig();
    let params = new URLSearchParams(window.location.search);
    let code = params.get('code');
    let state = params.get('state');
    if (code && state && storage.isLogin() == false) {
      let that = this;
      api.post("/weixin/openLogin", {code: code, state: state},{is_alert:true}).then(function (resp) {
        storage.setLoginToken(resp.data.token);
        storage.setUserInfo(resp.data.user);
        that.user = resp.data.user;
        that.isLogin = storage.isLogin();
      });
    }
    let invitCode = params.get('invitCode');
    if (invitCode) {
      this.invitCode = invitCode;
    }
  },
  mounted() {
    let that = this;
    let clipboard = new window.ClipboardJS('.clipboard-btn');
    clipboard.on('success', function () {
      that.$message.success("复制成功");
    });
    clipboard.on('error', function () {
      that.$message.error("复制失败");
    });
  },
  methods: {
    showWeixinModal() {
      this.weixin_visible = true;

      // api.get("/weixin/login", {}).then(function (resp)
      //   new window.WxLogin({
      //     self_redirect: false,
      //     id: "login_container",
      //     appid: resp.data.appid,
      //     scope: resp.data.scope,
      //     redirect_uri: resp.data.redirect_uri,
      //     state: resp.data.state,
      //     style: "white",
      //     href: location.href,
      //   });
      // });
      if(this.authId){
        clearInterval(this.authId);
        this.authId = 0;
      }
      let that = this;
      api.get("/weixin/qrlogin", {is_alert:true}).then(function (resp){
        that.xtoken = resp.data.xtoken;
        that.weixin_qrlogin = resp.data.qrcode_url;

        that.authId = setInterval(that.validateWxLogin,2000);
      });
    },
    showOrderModal() {
      if (storage.isLogin() === false) {
        this.showWeixinModal();
        return;
      }
      this.order_visible = true;
      let that = this;
      api.get("/user/order", {}).then(function (resp) {
        that.orderList = resp.data;
      });
    },
    showQunModal() {
      this.qun_visible = true;
    },
    showExchangeModal() {
      this.exchange_visible = true;
    },
    showJoinModal() {
      this.join_visible = true;
    },
    showContactModal() {
      this.contact_visible = true;
    },
    showActivityModal() {
      if (storage.isLogin() === false) {
        this.showWeixinModal();
        return;
      }
      this.vip_item = {code_type:'',price:'-'};
      this.activity_visible = true;
      this.getActivityConfig();
    },
    closeActivityModal() {
      this.activity_visible = false;
      if(this.authId){
        clearInterval(this.authId);
        this.authId = 0;
      }
    },
    showFreeModal(e) {
      if(e.target.className == "invit-input"){
        return;
      }
      if (storage.isLogin() === false) {
        this.showWeixinModal();
        return;
      }
      let that = this;
      api.post("/code/takeOnce", {invit_code:this.invitCode},{is_alert:true}).then(function (resp){
        that.exchangeCode = resp.data.code;
        that.exchange_visible = true;
      });
    },
    showProtocolModal() {
      this.protocol_visible = true;
    },
    toggleMute() {
      this.isMuted = !this.isMuted;
      this.$refs.videoPlayer.muted = this.isMuted;
      console.log("toggleMute=>", this.isMuted)
    },
    redirctInviter() {
      api.get("/user/inviter", {is_alert:true}).then(function (resp) {
        // location.href = resp.data.url;
        window.open(resp.data.url, '_blank');
      });
    },
    downloadApp1() {
      if (this.config.window_download_url1) {
        window.open(this.config.window_download_url1, '_blank');
        // location.href = this.config.window_download_url1;
      } else {
        this.$message.error("获取下载链接失败，请联系客服！")
      }
    },
    downloadApp2() {
      if (this.config.window_download_url2) {
        window.open(this.config.window_download_url2, '_blank');
        // location.href = this.config.window_download_url2;
      } else {
        this.$message.error("获取下载链接失败，请联系客服！")
      }
    },
    getActivityConfig() {
      let that = this;
      api.get("/config/activity", {}).then(function (resp) {
        that.vip_list = resp.data.vip_list;
        that.svip_list = resp.data.svip_list;
        that.zvip_list = resp.data.zvip_list;
        // if (resp.data.vip_list && resp.data.vip_list.length > 0) {
        //   that.vip_item = resp.data.vip_list[0];
        // }else if (resp.data.svip_list && resp.data.svip_list.length > 0) {
        //   that.vip_item = resp.data.svip_list[0];
        // }else if (resp.data.zvip_list && resp.data.zvip_list.length > 0) {
        //   that.vip_item = resp.data.zvip_list[0];
        // }
      })
    },
    selectActivityVip(item) {
      this.vip_item = item;
      this.createActivityOrder();
    },
    selectActivityIdx(idx) {
      if(idx == this.activityIdx){
        return;
      }
      this.activityIdx = idx;
      this.payQrcode = "assets/empty.png";
      this.vip_item = {code_type:'',price:'-'};
    },
    validateWxLogin() {
      let that = this;
      api.post("/weixin/qrauth", {xtoken:this.xtoken},{is_alert:true}).then(function (resp){
        if(resp.data.is_quit){
          clearInterval(that.authId);
          that.authId = 0;
        }
        if(resp.data.is_login){
          storage.setLoginToken(resp.data.token);
          storage.setUserInfo(resp.data.user);
          that.user = resp.data.user;
          that.isLogin = storage.isLogin();

          that.weixin_visible = false;
        }
      });
    },
    createActivityOrder(){
      let item = this.vip_item;
      if(!item.code_type){
        this.$message.error("请选择支付套餐！")
        return;
      }
      let that = this;
      api.post("/weixin/createOrder", item,{is_alert:true}).then(function (resp){
        that.payQrcode = resp.data.qrcode_url;
        that.payOrderId = resp.data.order_id;
        if(that.authId){
          clearInterval(that.authId);
          that.authId = 0;
        }
        that.authId = setInterval(that.queryActivityOrder,2000);
      }).catch(function (){
        if(that.authId){
          clearInterval(that.authId);
          that.authId = 0;
        }
        that.payQrcode = "assets/empty.png";
      })
    },
    queryActivityOrder(){
      if (!this.payOrderId) {
        return;
      }
      let that = this;
      api.get("/weixin/queryPay", {params:{order_id:this.payOrderId},is_alert:true}).then(function (resp){
        if(resp.data.is_pay){
          clearInterval(that.authId);
          that.authId = 0;

          that.exchangeCode = resp.data.cdk_code;
          that.exchange_visible = true;
        }
      });
    },
    showConfirmQuit() {
      let that = this;
      Modal.confirm({
        title: '确认退出',
        content: '您确定要退出登录吗？',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        class:"quit_login_confirm",
        onOk: () => {
           that.$message.success("复制成功");
          storage.setLoginToken(null);
          storage.setUserInfo(null);
          that.user = null;
          that.isLogin = false;
        },
        onCancel() {
          console.log('Cancelled logout');
        }
      });
    }
  },
}
</script>

<style>
@import './assets/new.css';
</style>